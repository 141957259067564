import React, { createContext, useState, useEffect } from 'react'
// @ts-ignore
import Cache from 'js-cache'
const CacheStoreContext = createContext<{
  cache: any
}>({
  cache: undefined,
})

const CacheStoreProvider = (props: { children: React.ReactNode }) => {
  const { children } = props
  const [cache, setCache] = useState<any>(undefined)

  useEffect(() => {
    try {
      setCache(new Cache())
    } catch (error) {
      console.error(error)
      setCache(undefined)
    }
  }, [])

  return (
    <CacheStoreContext.Provider
      value={{
        cache,
      }}
    >
      {children}
    </CacheStoreContext.Provider>
  )
}

export { CacheStoreContext, CacheStoreProvider }
