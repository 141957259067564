export function validatePhone(phone: string | number): boolean {
  return /^\d{11}$/.test(`${phone}`)
}

export function toNumber(value: string | number): number {
  const numberValue = Number(value)
  return Number.isNaN(numberValue) ? 0 : numberValue
}

export function unicodeToChar(text: string) {
  return text.replace(/\\u[\dA-F]{4}/gi, function(match) {
    return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
  })
}

export function getBoundingClientRect(element: HTMLElement | Element) {
  const rect = element.getBoundingClientRect()
  // whether the IE version is lower than 11
  const isIE = navigator.userAgent.indexOf('MSIE') !== -1

  // fix ie document bounding top always 0 bug
  const rectTop =
    isIE && element.tagName === 'HTML' ? -element.scrollTop : rect.top

  return {
    left: rect.left,
    top: rectTop,
    right: rect.right,
    bottom: rect.bottom,
    width: rect.right - rect.left,
    height: rect.bottom - rectTop,
  }
}
